import { likeRegExp } from '@/offline/database'
import { ACCION_EJEC } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  _selectAcciones (Vue, idparteTrabajo, idsubsis, idestAccion, retirado) {
    const tables = Vue.$offline.db.tables
    let whereQueryFilter = []
    let where = []
    if (retirado) {
      whereQueryFilter.push(tables.material_sistema.idalmacen.isNotNull())
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
        tables.subsis.idsubsis.eq(idsubsis),
        tables.accion_ejec.idest_accion_ejec.eq(idestAccion),
        ...whereQueryFilter,
      )
    )
    return Vue.$offline.db
      .select(
        tables.maccion.descripcion_corta,
        tables.maccion.orden,
        Vue.$offline.db.fn.count(
          tables.accion_ejec.unidades
        ).as('cant_acciones')
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.accion_ejec,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.accion_ejec.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(...where)
      .groupBy(
        tables.maccion.descripcion_corta
      )
      .orderBy(tables.maccion.orden)
      .orderBy(tables.maccion.descripcion_corta)
      .exec()
  },
  async _selectCantidadMaterial(Vue, idparteTrabajo, idsubsis) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        Vue.$offline.db.fn.sum(
          tables.material_sistema.unidades
        ).as('cant_materiales')
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
          tables.material_sistema.estado.gt(0),
          tables.material_sistema.idsubsis.eq(idsubsis),
        )
      ).exec()
  },
  async _addSubqueriesMaterialAfectado (Vue, rows, retirado) {
    for (let row of rows) {
      const accionesRealizadas = await this._selectAcciones(
        Vue, row.parte_trabajo_matsist.idparte_trabajo, row.subsis.idsubsis, ACCION_EJEC.estados.realizada, retirado
      )
      row.accionesRealizadas = []
      row.accionesRealizadasTotal = 0
      for (let accion of accionesRealizadas) {
        row.accionesRealizadas.push({
          cant_acciones: accion.cant_acciones,
          desc_acciones: accion.maccion.descripcion_corta
        })
        row.accionesRealizadasTotal += accion.cant_acciones
      }
      const accionesPendientes = await this._selectAcciones(
        Vue, row.parte_trabajo_matsist.idparte_trabajo, row.subsis.idsubsis, ACCION_EJEC.estados.pendiente, retirado
      )
      row.accionesPendientes = []
      row.accionesPendientesTotal = 0
      for (let accion of accionesPendientes) {
        row.accionesPendientes.push({
          cant_acciones: accion.cant_acciones,
          desc_acciones: accion.maccion.descripcion_corta
        })
        row.accionesPendientesTotal += accion.cant_acciones
      }
      const cantidadMaterial = await this._selectCantidadMaterial(
        Vue, row.parte_trabajo_matsist.idparte_trabajo, row.subsis.idsubsis,
      )
      row.cantidadMateriales = cantidadMaterial[0].cant_materiales
    }
    return _.sortBy(rows, ['cantidadMateriales'])
  },
  _selectMaterialAfectadoBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        tables.parte_trabajo_matsist.idparte_trabajo,
        tables.subsis.orden,
        tables.subsis.codigo,
        tables.subsis.descripcion,
        tables.subsis.idsubsis,
        Vue.$offline.db.fn.sum(
          tables.material_sistema.unidades
        ).as('cant_materiales')
      )
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )

      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .groupBy(
        tables.parte_trabajo_matsist.idparte_trabajo,
        tables.subsis.orden,
        tables.subsis.codigo,
        tables.subsis.descripcion,
        tables.subsis.idsubsis,
      )
  },
  async selectMaterialAfectado (Vue, filter, search, sorter, page, idparteTrabajo, retirado) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    let whereQueryFilter = []
    if (retirado) {
      whereQueryFilter.push(tables.material_sistema.idalmacen.isNotNull())
    }
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.subsis.codigo.match(reQ),
          tables.subsis.descripcion.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
        tables.material_sistema.estado.gt(0),
        ...whereQueryFilter,
        ...whereSearch,
        ...whereFilter,
      )
    )
    const rows = await this._selectMaterialAfectadoBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
      .orderBy(tables.subsis.orden)
      .orderBy(tables.subsis.descripcion)
      .orderBy(tables.subsis.idsubsis)
      .exec()
    return [
      await this._addSubqueriesMaterialAfectado(Vue, rows, retirado),
      []
    ]
  },
  async selectMaterialAfectadoRows (Vue, pks, idparteTrabajo, retirado) {
    const tables = Vue.$offline.db.tables
    let whereQueryFilter = []
    if (retirado) {
      whereQueryFilter.push(tables.material_sistema.idalmacen.isNotNull())
    }
    const rows = await this._selectMaterialAfectadoBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
          tables.subsis.idsubsis.in(pks),
          ...whereQueryFilter
        )
      )
      .exec()
    return (await this._addSubqueriesMaterialAfectado(Vue, rows))
  },
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .leftOuterJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          Vue.$offline.db.op.or(
            tables.checklist_ot.estado.gt(0),
            tables.checklist_ot.idchecklist_ot.isNull(),
          )
        )
      )
      .exec()
    )[0]
  },
}